.all {
  padding: 2%;
}

.DScontainer {
  padding: 6%;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}

label {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.ContactContent {
  padding-left: 60px;
  padding-right: 60px;
}
.DSslogan {
  flex: 1;
  flex-direction: row;
  text-align: left;
  line-height: 0.8cm;
  font-size: 18px;

  font-weight: bolder;
  text-shadow: 1cm;
  margin-top: 5%;
  margin-bottom: 2%;
}

.DStopside {
  display: flex;
}

.DSbutton {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  color: white;
  background: black;
  margin-top: 1%;
  float: right;
}

.DSlogo1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DSlogoOne {
  width: 35vw;
  height: auto;
}

.DSEmailInput {
  font-size: 16px;
  font-weight: bold;
}

.--text-center {
  margin: auto;
}

.DSsocialMedia {
  max-width: 750px;
  display: flex;
  white-space: nowrap;
  justify-content: space-evenly;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
  font-size: 50px;
}

textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 300px;
  max-height: 300px;
  border-width: 2px;
  border-color: black;
  border-radius: 5px;
  margin-top: 5px;
  padding: 8px;
  font-size: 20px;
}
textarea::-webkit-input-placeholder {
  font-size: 20px;
}

.DSsecondHead {
  font-weight: bold;
  color: black;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 40px;
  text-align: center;

  font-style: normal;
}
.form-group {
  padding-top: 20px;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left; */
}

.DSkisaform {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2%;
}

.ContactInformationHeading {
  font-size: 43px;
  margin-top: 10%;
  margin-bottom: 15%;
  font-weight: bold;
  line-height: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.ContactInformationContents li {
  margin-top: 0.7rem;
}
form {
  padding: 0;
}

.form-control {
  border: 2px solid black;
  border-radius: 4px;
  font-size: 16px;
  height: 35px;
  margin-top: 5px;
  padding: 8px;
  width: 100%;
}

.DSOtherContact {
  font-size: 16px;
  margin-top: 8%;
  padding-left: 21%;

  color: black;
}

.DSOtherContact a:link {
  color: #ff6961;
}

.DSOtherContact a:visited {
  color: #ff6961;
}
.DSOtherContact a:active {
  color: #ff6961;
}
