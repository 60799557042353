@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

.list {
  margin-top: 30px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.p {
  text-align: center;
  font-size: x-large;
}

.p1 {
  font-size: x-large;
}

.intro {
  text-align: center;
}

.section {
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

.title .img .logo {
  padding-left: 20%;
  width: 80%;
}

.Member {
  float: left;
}

.Major {
  float: right;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;
}

.timeline-item-content::after {
  content: ' ';
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-container::after {
  background-color: #e17b77;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: ' ►';
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 2;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
  }

  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timeline-item-content time {
    margin-top: 20px;
  }

  .timeline-item-content a {
    text-decoration: underline;
  }

  .timeline-item-content a::after {
    display: none;
  }
}

:root {
  --orange: #ff7800;
  --black: #130f40;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.slider {
  position:relative;
  top: 6rem;
  width: 100vw;
  height: 100vh;
  margin-bottom: 10%;
}

.slider img {
  position: absolute;
  top: -50%; /* Crop된 부분을 보여주기 위해 이미지를 위로 이동 */
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slider img.active {
  opacity: 1;
}


.heading {
  text-align: center;
  padding: 2rem 0;
  font-size: 3rem;
  color: #000000;
}

.heading span {
  background: #ff6a00;
  color: #fff;
  display: inline-block;
  padding: 0.5rem 3rem;
  clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.8rem 3rem;
  font-size: 1.7rem;
  border-radius: 0.5rem;
  border: 0.2rem solid #000000;
  color: #000000;
  cursor: pointer;
  background: none;
}

.btn:hover {
  background: #ff6a00;
  color: #fff;
}

.features .box-container {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2.5rem;
  padding-inline: 15%;
  padding-bottom: 20%;
}

.features .box-container .box {
  padding: 3rem 2rem;
  background: #fff;
  outline: var(--outline);
  outline-offset: -1rem;
  text-align: center;
  box-shadow: var(--box-shadow);
  width: 21rem;
}

.features .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.features .box-container .box img {
  margin: 1rem 0;
  height: 12rem;
}

.features .box-container .box h3 {
  font-size: 2.5rem;
  line-height: 1.8;
  color: var(--black);
}

.features .box-container .box p {
  font-size: 1.5rem;
  line-height: 1.8;
  color: var(--light-color);
  padding: 1rem 0;
}
