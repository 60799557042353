.sponsor-item {
	width: 300px;
	height: 300px;
}
.centered-list {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 90%;
	max-width: 50rem;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
