.modal {
  z-index: 100;
  position: fixed;
  /* 22vh: 전체 뷰포트 높이의 상단 22%. 줄일수록 올라감 */
  top: 17vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  background: #164734;
  color: white;
}

.modal__header button {
  background-color: transparent;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem;
  overflow-y: auto;
}
.gallery {
  height: 60vh;
}
.modal__content .confirm {
  padding: 1rem;
  overflow-y: auto;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

.modal__content span {
  font-size: large;
}

.modal__content .modal__date {
  margin-top: 10px;
}

.modal__content .modal__creator {
  font-weight: bold;
  color: #164734;
}

@media (min-width: 870px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
