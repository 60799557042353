section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  margin-top: 5%;
  max-width: 1300px;
  width: 70%;
}

h1 {
  font-size: 40px;
  line-height: 100px;
  text-align: center;
  margin-bottom: 120px;
}

h3 {
  padding: 12px 12px 0px 12px;
  margin: 20px 20px 0px 20px;
  text-align: center;
  font-size: 18px;
}
p {
  margin: 12px;
  font-size: 16px;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  text-align: center;
  padding-left: 17%;
  padding-right: 17%;
}

.card {
  /* flex: 0 0 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px); */
  width: 270px;
  padding: 20px;
  margin-bottom: 30px;
  transition: 0.3s;
}
