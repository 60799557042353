.blink2{
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid black;
  border-radius: 4px;
  background: black;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #ff7800;
  border-color: #ff7800;
}

.button--inverse {
  background: transparent;
  color: #ff7800;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff7800;
}

.button--danger {
  background: #b52525;
  border-color: #b52525;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.auth {
  height: 3.3rem;
  border-radius: 20px;
  transition: all ease 0.2s;
  display: flex;
  align-items: center;
  font-size: large;
  
}
.auth a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  height: 3.3rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  width: 5rem;
}

.auth a:hover {
  background-color: #F98909;
}

.auth a:before {
  background-color: transparent;
}

.auth a img{
  border-radius: 50%;
  border:2px solid #F98909;
  
}

.auth a img:hover{
  box-shadow: 0 0 8px #646464;
}



