.FlexRow_c {
  padding-top: 20px;
  padding-left: 0%;
  padding-right: auto;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}
.border-box {
  display: flex;
  text-align: right;
  width: 400px;
  height: 400px;
  margin-top: 20px;
  background-color: #d3d3d3;
}
.FlexCol_c {
  display: block;
  justify-content: space-between;
}

.upload {
  padding: 1%;
  border-radius: 5px;
  color: white;
}

.upload:hover {
  background-color: blue;
}

.borderBox {
  justify-items: center;
}

.feedback-input {
  border-radius: 5px;
}

.d-block_w-100 {
  padding-bottom: auto;
}
