.Menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.Menu li {
  margin: 0;
}

.Menu button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.dropdown {
  float: left;
  width: 150px;
}

.dropdown-menu {
  margin-top: 8px;
  font-size: 16px;
}

.dropdown-menu a {
  float: none;
  color: black;
  padding-top: 5px;
  border: none;
  text-decoration: none;
  display: block;
  text-align: start;
}

/* Contact */
.Menu .navlink-btn {
  width: 150px;
  text-align: start;
}
.Menu .navlink-btn a {
  font-weight: bold;
  font-size: 18.5px;
  color: #ff6a00;
}

/* Navlinks */
.dropdown .dropbtn {
  font-weight: bold;
  font-size: 19px;
  border: none;
  outline: none;
  color: #ff6a00;
  background-color: inherit;
  font-family: inherit;
  text-align: start;
  align-items: center;
}
.dropbtn a {
  color: black;
}
.dropbtn a:hover {
  color: black !important;
}

@media (min-width: 870px) {
  .Menu {
    flex-direction: row;
    color: black;
  }

  /* .Menu li {
    margin: 0 -0.5rem;
  } */

  .Menu button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .Menu .dropdown-menu {
    align-items: center;
    font-size: 17px;
    display: none;
    position: absolute;
    background-color: #fff;
    width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .dropdown-menu a {
    padding: 12px 16px;
    text-align: center;
  }
  .dropdown .dropbtn {
    text-align: center;
    color: black;
  }
  .Menu .navlink-btn {
    text-align: center;
  }
  .Menu .navlink-btn a {
    color: black;
  }
  .dropbtn {
    padding: 10px 5px;
  }

  .dropbtn a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }
}

/* .nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #f8df00;
  border-color: #292929;
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

} */
