.items-list {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 90%;
	max-width: 50rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
