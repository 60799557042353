.buttons {
  display: flex;
}
.buttons div {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.buttons span {
  cursor: pointer;
  font-size: 17px;
}
.buttons div:hover button,
.buttons div:hover span {
  color: rgba(0, 0, 0, 0.6);
}
.buttons button {
  font-size: 20px;
  display: inline-block;
  padding-right: 7px;
  cursor: pointer;
  background-color: transparent;
}

.parent1 {
  width: 190px;
  /* margin: 10px; */
  /* display: inline-block; */
  /* justify-content: center; */
  text-align: center;
  /* border: 1px solid #ccc; */
  margin: 0.3rem;
}

.parent1 .box1 .element {
  margin: 10px 0;
  padding: 10px;
}
/*
.img-gallery {
  display: inline-flex;
  flex-direction: column;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}
*/
.img-gallery img {
  width: 190px;
  height: 190px;
  object-fit: cover;
  padding: 1%;
  -webkit-filter: brightness(100%);
}
.img-gallery img:hover,
.img-gallery img:active {
  /* background: rgba(0, 0, 0, 0.65); */
  cursor: pointer;
  -webkit-filter: brightness(70%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.desc h4 {
  padding: 15px;
  text-align: center;
  color: #ff7800;
}
